import React, { Component } from 'react';
import PropTypes from 'prop-types';

// lib
import trackPageVisit from 'lib/track-page-visit';

// vendor components & lib
import { graphql, StaticQuery } from 'gatsby';

// components
import PageTopSection from 'components/common/page-top-section';
import ServicebotPricing from 'components/service-bot/pricing';
import Layout from 'components/common/layout';

// styles
import 'stylesheets/subsctibe/pricing.scss';

// component function
class ServicebotPricingPage extends Component {
  static propTypes = {
    data: PropTypes.object,
    navigate: PropTypes.func.isRequired,
  };

  render() {
    const { data, navigate } = this.props;
    const { image, mobileImage } = data;

    const imageChildFluid = image.childImageSharp.fluid;
    const imageMobileChildFluid = mobileImage.childImageSharp.fluid;

    return (
      <Layout title="Pricing">
        <main className="pricing">
          <PageTopSection
            image={imageChildFluid}
            mobileImage={imageMobileChildFluid}
            description="Running a business? Deduct this as a business expense from your tax return!*"
            title="Pricing"
            className="pricing_top-section"
            titleClassName="pricing_top-section_title"
            descriptionClassName="pricing_top-section_description"
          />

          <ServicebotPricing navigate={navigate} />
        </main>
      </Layout>
    );
  }
}

export default trackPageVisit(props => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "pricing.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mobileImage: file(relativePath: { eq: "pricing-mobile.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <ServicebotPricingPage data={data} {...props} />}
  />
));
